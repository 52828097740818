import { ConsignmentProperties, Offer, ReactiveSettings, SubmissionOfferTypes } from './typeORM';
import noop from './utils/noop';

export const API_URL =
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_HOST : 'http://localhost:3001';

export const NETSUITE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://1292193.app.netsuite.com'
    : 'https://1292193-sb1.app.netsuite.com';

export const PAGE_SIZE = 20;

export const stateArray = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const idStates = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Islands',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const builds = [
  'Shimano Dura-Ace',
  'Shimano Ultegra',
  'Shimano 105',
  'Shimano XTR',
  'Shimano XT',
  'Shimano SLX',
  'Shimano GRX',
  'SRAM Apex',
  'SRAM Red',
  'SRAM Force',
  'SRAM Rival',
  'SRAM XX',
  'SRAM X0',
  'SRAM GX',
  'SRAM NX',
  'SRAM SX',
  'Campagnolo Record',
  'Campagnolo Super Record',
  'Campagnolo Chorus',
  'Campagnolo Centaur',
];

export enum netsuiteFields {
  WIPSTATE = 'custitem_wip_state',
}

export enum SubmissionStatusIds {
  INITIATED = 1,
  PROPOSED = 2,
  ACCEPTED = 3,
  DECLINED_USER = 4,
  DECLINED_BUYER = 5,
  COMPLETED = 6,
  EXPIRED = 7,
  RESUBMITTED = 8,
  PENDING = 9,
  FAILED = 10,
  FAILED_SENT = 11,
  FAILED_RETURNED = 12,
  FAILED_CONFIRMED = 14,
  EVALUATED = 15,
  EXTENDED = 16,
  RECEIVED = 17,
}

export enum SubmissionStatuses {
  INITIATED = 'initiated',
  PROPOSED = 'proposed',
  ACCEPTED = 'accepted',
  DECLINED_USER = 'declined:user',
  DECLINED_BUYER = 'declined',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
  RESUBMITTED = 'resubmitted',
  PENDING = 'pending',
  FAILED = 'failed',
  FAILED_SENT = 'failed:sent',
  FAILED_RETURNED = 'failed:returned',
  FAILED_CONFIRMED = 'failed:confirmed',
  EVALUATED = 'evaluated',
  EXTENDED = 'extended',
  RECEIVED = 'received',
}

export enum SubmissionPartnerSites {
  'giant' = 1,
  'liv' = 2,
  'dealers' = 3,
}

export const TRANSITIONABLE_STATUSES = [2, 5, 6, 9, 15, 16, 17];

export const PARTNER_VOUCHER_SITE_IDS = [SubmissionPartnerSites.giant, SubmissionPartnerSites.liv];
export const TRADEUP_ONLY_SITE_IDS = [SubmissionPartnerSites.dealers];

export enum OfferPaymentTermIds {
  NET0 = 1,
  NET30 = 2,
  NET45 = 3,
  NET60 = 4,
  NET120 = 5,
  NONE = 0,
}

export enum OfferPaymentTermLabel {
  NET0 = 'Paid Immediately',
}

export interface OfferFieldOld {
  amountUsd: number | null;
  availableToSeller: boolean;
  disabledOfferWithAmount: boolean;
}

export type OfferField = {
  amountUsd: number | null; //fallback amount for consignment
  availableToSeller: boolean;
  disabledOfferWithAmount: boolean;
  consignorSplitPct?: number | null; // displayed as a percentage, so multiplied by 100 compared to what's in db
};

export type UpdatedOffer = Pick<
  Offer,
  | 'offerTypeName'
  | 'amountUsd'
  | 'availableToSeller'
  | 'offerPaymentTermsId'
  | 'consignorSplit'
  | 'estimatedPaymentRangeMultipliers'
>;

export interface OfferType {
  label: string;
  placeholder: string;
  toggleable: boolean;
  completedMsg: string;
  paymentTermsId: number;
  enableSingleOption(partnerSiteId: number | null): boolean | void;
  defaultAvailableToSeller(
    reactiveSettings: ReactiveSettings,
    partnerSiteId: number | null,
  ): boolean;
  consignmentProperties?(reactiveSettings: ReactiveSettings): ConsignmentProperties; //presence indicates offer is consignment
}

export const DEFAULT_MINIMUM_SALE_PRICE_MULTIPLIER = 0.9;
export const DEFAULT_MAXIMUM_SALE_PRICE_MULTIPLIER = 1.25;

/**
 * If you add a new cash based offer you need to add it to CASH_BASED_OFFER_TYPES in server/src/constants/SubmissionConstants.ts
 */
export const offerTypesConfig: { [key: string]: OfferType } = {
  [SubmissionOfferTypes.BASIS_ONLY]: {
    label: 'Offer Basis',
    placeholder: 'Offer Basis only',
    toggleable: false,
    completedMsg: '',
    enableSingleOption: noop,
    paymentTermsId: OfferPaymentTermIds.NONE,
    defaultAvailableToSeller: (reactiveSettings, _p) =>
      !!reactiveSettings.availableToSeller[SubmissionOfferTypes.CONSIGNMENT_V1],
  },
  [SubmissionOfferTypes.DELAYED_CASH_60D]: {
    label: '60 Day Delayed Cash Offer',
    placeholder: '60 Day Delayed cash offer',
    toggleable: true,
    completedMsg: '',
    enableSingleOption: noop,
    paymentTermsId: OfferPaymentTermIds.NET60,
    defaultAvailableToSeller: (reactiveSettings: ReactiveSettings, _p) =>
      !!reactiveSettings.availableToSeller[SubmissionOfferTypes.DELAYED_CASH_60D],
  },
  [SubmissionOfferTypes.CONSIGNMENT_V1]: {
    label: 'Consignment with 120 Day Fallback',
    placeholder: 'Consignment with 120 Day Fallback',
    toggleable: true,
    completedMsg: '',
    enableSingleOption: noop,
    paymentTermsId: OfferPaymentTermIds.NET120,
    defaultAvailableToSeller: (reactiveSettings: ReactiveSettings, _p) =>
      !!reactiveSettings.availableToSeller[SubmissionOfferTypes.CONSIGNMENT_V1],
    consignmentProperties: (reactiveSettings: ReactiveSettings) =>
      reactiveSettings.properties?.consignmentProperties?.[SubmissionOfferTypes.CONSIGNMENT_V1] ??
      {}, // defaults applied when read - this allows properties json to be sparse or empty
  },
  [SubmissionOfferTypes.DELAYED_CASH_45D]: {
    label: '45 Day Delayed Cash Offer',
    placeholder: '45 Day Delayed cash offer',
    toggleable: true,
    completedMsg: '',
    enableSingleOption: noop,
    paymentTermsId: OfferPaymentTermIds.NET45,
    defaultAvailableToSeller: (reactiveSettings, _p) =>
      !!reactiveSettings.availableToSeller[SubmissionOfferTypes.DELAYED_CASH_45D],
  },
  [SubmissionOfferTypes.DELAYED_CASH_30D]: {
    label: '30 Day Delayed Cash Offer',
    placeholder: '30 Day Delayed cash offer',
    toggleable: true,
    completedMsg: '',
    enableSingleOption: noop,
    paymentTermsId: OfferPaymentTermIds.NET30,
    defaultAvailableToSeller: _p => false,
  },
  [SubmissionOfferTypes.CASH]: {
    label: 'Instant Cash Offer',
    placeholder: 'instant cash offer',
    toggleable: true,
    completedMsg: '',
    enableSingleOption: noop,
    paymentTermsId: OfferPaymentTermIds.NET0,
    defaultAvailableToSeller: (reactiveSettings, _p) =>
      !!reactiveSettings.availableToSeller[SubmissionOfferTypes.CASH],
  },
  [SubmissionOfferTypes.CREDIT]: {
    label: 'Credit Offer',
    placeholder: 'credit offer',
    toggleable: true,
    completedMsg: '',
    enableSingleOption: noop,
    paymentTermsId: OfferPaymentTermIds.NET0,
    defaultAvailableToSeller: (reactiveSettings, _p) =>
      !!reactiveSettings.availableToSeller[SubmissionOfferTypes.CREDIT],
  },
  [SubmissionOfferTypes.TRADE]: {
    label: 'Trade Offer',
    placeholder: 'trade offer',
    toggleable: true,
    enableSingleOption: (partnerSiteId: number | null) => {
      return !!partnerSiteId && TRADEUP_ONLY_SITE_IDS.includes(partnerSiteId);
    },
    completedMsg: '',
    paymentTermsId: OfferPaymentTermIds.NET0,
    defaultAvailableToSeller: (reactiveSettings, partnerSiteId: number | null) => {
      return (
        !!reactiveSettings.availableToSeller[SubmissionOfferTypes.TRADE] ||
        (!!partnerSiteId && TRADEUP_ONLY_SITE_IDS.includes(partnerSiteId))
      );
    },
  },
  [SubmissionOfferTypes.PARTNER_VOUCHER]: {
    label: 'Partner Offer',
    placeholder: 'partner offer',
    toggleable: false,
    enableSingleOption: (partnerSiteId: number | null) => {
      return !!partnerSiteId && PARTNER_VOUCHER_SITE_IDS.includes(partnerSiteId);
    },
    completedMsg: '',
    paymentTermsId: OfferPaymentTermIds.NET0,
    defaultAvailableToSeller: (_rs, partnerSiteId: number | null) => {
      return !!partnerSiteId && PARTNER_VOUCHER_SITE_IDS.includes(partnerSiteId);
    },
  },
};
